import { Translate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import DropdownList from '../../components/common/Dropdown/DropdownList';
import EmployeeList from '../Employee/EmployeeList/EmployeeList';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
   addCustomPayrollList,
   addEmployee,
   deleteCustomPayroll,
   resetEntireState,
   resetEntireStateCustom,
} from '../../Redux/actions/customPayrollActions';
import { generateNumericId, generateUniqueId } from '../../utils/PayrollUtils';
import { Col, Row } from 'react-bootstrap';
import GreenCommonBtn from '../../components/common/Buttons/GreenCommonBtn';
import { REACT_APP_URL_FIND_CUSTOM_PAYROLL } from '../../components/common/Routes/URLs';
import TempEdgeApi from '../../services/TempEdgeApi';
import { normalizeDateSendReports } from '../../utils/ReGexs';
import { maxValuesSelected } from '../../components/common/Constants/Constants';
import { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import BlueCommonBtn from '../../components/common/Buttons/BlueCommonBtn';
import DropdownClientWeekRange from '../Payroll/Attendance/DropdownClientWeekRange';

const validateAndSetHeaders = ({ headersFindsOriginal, initialCodeHeaders, dataGlobal, maxValuesSelected, headersMapRef }) => {
   if (headersFindsOriginal.length > 6) {
      return {
         headers: headersFindsOriginal.slice(0, 6),
         message: maxValuesSelected,
      };
   } else if (headersFindsOriginal.length < 3) {
      const needed = 3 - headersFindsOriginal.length;
      const existingCustomIds = headersFindsOriginal.map((h) => h.customPayrollTypeId);

      const candidates = initialCodeHeaders.filter((code) => !existingCustomIds.includes(Number(code))).slice(0, needed);

      const headersToAdd = candidates.map((code) => {
         const stableHeader = getOrCreateStableHeader({
            code,
            headersMapRef,
            dataGlobal,
            baseCount: headersFindsOriginal.filter((h) => h.customPayrollTypeId === code).length,
         });
         return stableHeader;
      });

      return {
         headers: [...headersFindsOriginal, ...headersToAdd],
         message: null,
      };
   } else {
      return {
         headers: headersFindsOriginal,
         message: null,
      };
   }
};

function getOrCreateStableHeader({ code, headersMapRef, dataGlobal, baseCount }) {
   const customPayrollTypeId = Number(code);
   if (!headersMapRef.current[customPayrollTypeId]) {
      headersMapRef.current[customPayrollTypeId] = {};
   }

   const occ = baseCount + 1;

   let stableHeader = headersMapRef.current[customPayrollTypeId][occ];
   if (!stableHeader) {
      const header = dataGlobal.find((item) => Number(item.customPayrollTypeId) === customPayrollTypeId);
      const newHeader = {
         _idHeader: generateNumericId(),
         title: `${header?.reportTitle || 'Default Title'} - ${occ}`,
         key: customPayrollTypeId,
         customPayrollTypeId,
      };
      headersMapRef.current[customPayrollTypeId][occ] = newHeader;
      stableHeader = newHeader;
   }

   return stableHeader;
}

const CustomPayrollSearch = ({
   clients,
   selectClient,
   setSelectClient,
   errors,
   selectCompany,
   findPayrollWeek,
   employees,
   setModal,
   dataGlobal,
   initialCodeHeaders,
   setHeaderCustomPayroll,
   setMessage,
   weekActive = {
      start: new Date(),
      end: new Date(new Date().setDate(new Date().getDate() - 6)),
   },
   rangeDate,
   setRangeDate,
   customDate,
   setCustomDate,
}) => {
   const dispatch = useDispatch();
   const existingPayrollList = useSelector((state) => state.customPayrollReducer.payrollEntityList);

   const [headerLocal, setHeaderLocal] = useState([]);
   const [discoveredTypes, setDiscoveredTypes] = useState([]);

   const headersMapRef = useRef({});

   useEffect(() => {
      initialCodeHeaders.forEach((code) => {
         if (!headersMapRef.current[code]) {
            headersMapRef.current[code] = {};
            const headerItem = dataGlobal.find((item) => Number(item.customPayrollTypeId) === Number(code));
            const newHeader = {
               _idHeader: generateNumericId(),
               title: `${headerItem?.reportTitle || 'Default Title'} - 1`,
               key: Number(code),
               customPayrollTypeId: Number(code),
            };
            headersMapRef.current[code][1] = newHeader;
         }
      });
   }, [dataGlobal, initialCodeHeaders]);

   const handleSelectClient = (value) => {
      setSelectClient(value);
      selectCompany(value);
      dispatch(resetEntireStateCustom());
      setRangeDate(null);
      setCustomDate(null);
   };

   const handleFindPayrollWeek = () => {
      dispatch(resetEntireState());
      if (rangeDate?.value === 'CUSTOM') {
         findPayrollWeek(customDate);
      } else {
         findPayrollWeek(rangeDate.startDate);
      }
   };

   const getStableHeaders = (arrayType, dataGlobal) => {
      const headersResult = [];
      const occurrenceCount = {};

      arrayType.forEach((customPayrollTypeId) => {
         if (!occurrenceCount[customPayrollTypeId]) occurrenceCount[customPayrollTypeId] = 0;
         occurrenceCount[customPayrollTypeId]++;

         const occ = occurrenceCount[customPayrollTypeId];

         if (!headersMapRef.current[customPayrollTypeId]) {
            headersMapRef.current[customPayrollTypeId] = {};
         }

         if (headersMapRef.current[customPayrollTypeId][occ]) {
            headersResult.push(headersMapRef.current[customPayrollTypeId][occ]);
         } else {
            const matchingHeader = dataGlobal.find((header) => header.customPayrollTypeId === customPayrollTypeId);
            if (!matchingHeader) return;

            const sameIdCount = headersResult.filter((h) => h.customPayrollTypeId === customPayrollTypeId).length;
            const newHeader = {
               _idHeader: generateNumericId(),
               title: `${matchingHeader.reportTitle || 'Unknown'} - ${sameIdCount + 1}`,
               key: customPayrollTypeId,
               customPayrollTypeId,
            };

            headersMapRef.current[customPayrollTypeId][occ] = newHeader;
            headersResult.push(newHeader);
         }
      });

      return headersResult;
   };

   async function selectRow(event) {
      setMessage(null);
      const isRowSelected = !!event.currentTarget.classList.value.includes('table-active');
      const cellContent = [];
      event.currentTarget.querySelectorAll('td').forEach((td) => {
         cellContent.push(td.textContent);
      });

      const personId = parseInt(event.currentTarget.dataset.id);
      const generatedId = generateUniqueId();

      if (isRowSelected) {
         dispatch(
            addEmployee({
               id: generatedId,
               personId,
               employeeId: cellContent[0],
               firstName: cellContent[4],
               lastName: cellContent[3],
               labelPay: true,
               labelBill: true,
               deleteBill: false,
               deletePay: false,
               isNewEmployee: true,
            }),
         );

         const request = {
            orgId: JSON.parse(localStorage.getItem('agency'))?.organizationEntity?.orgId,
            startDate: normalizeDateSendReports(weekActive.start),
            endDate: normalizeDateSendReports(weekActive.end),
            dateList: normalizeDateSendReports(weekActive.end),
            clientId: selectClient.clientId,
            personId,
         };

         try {
            const { data: res } = await TempEdgeApi.post({
               url: REACT_APP_URL_FIND_CUSTOM_PAYROLL,
               payload: request,
            });

            if (res.status === 200 && res.code === 'TE00') {
               const arrayType = [];
               const localOccurrences = {};
               res.result.forEach((payroll) => {
                  const customPayrollTypeId = payroll.payCode?.customPayrollTypeId || payroll.billingCode?.customPayrollTypeId;
                  if (customPayrollTypeId == null) return;
                  localOccurrences[customPayrollTypeId] = (localOccurrences[customPayrollTypeId] || 0) + 1;

                  const globalOccurrences = arrayType.filter((id) => id === customPayrollTypeId).length;
                  if (localOccurrences[customPayrollTypeId] > globalOccurrences) {
                     arrayType.push(customPayrollTypeId);
                  }
               });

               // Fusionar arrayType con discoveredTypes en una variable local
               const allTypes = [...discoveredTypes];
               arrayType.forEach((t) => {
                  if (!allTypes.includes(t)) {
                     allTypes.push(t);
                  }
               });

               // Ahora tenemos todos los tipos descubiertos (previos + nuevos)
               // Generar headers a partir de allTypes
               const fullHeadersFromDiscovered = getStableHeaders(allTypes, dataGlobal);

               // Unir con headerLocal sin eliminar previos
               const combinedHeaders = [...headerLocal];
               fullHeadersFromDiscovered.forEach((h) => {
                  const exists = combinedHeaders.some((ch) => ch.customPayrollTypeId === h.customPayrollTypeId);
                  if (!exists) {
                     combinedHeaders.push(h);
                  }
               });

               const { headers, message: validationMessage } = validateAndSetHeaders({
                  headersFindsOriginal: combinedHeaders,
                  initialCodeHeaders,
                  dataGlobal,
                  maxValuesSelected,
                  headersMapRef,
               });

               if (validationMessage) {
                  setMessage(validationMessage);
               }

               // Actualizar estados
               setHeaderLocal(headers);
               setHeaderCustomPayroll(headers);
               setDiscoveredTypes(allTypes); // Guardamos la nueva lista global de tipos

               // Crear updatedCustomPayrollList con los headers actuales
               const headersFinds = [...headers];
               const updatedCustomPayrollList = res.result.map((data) => {
                  const customPayrollTypeId = data.payCode?.customPayrollTypeId || data.billingCode?.customPayrollTypeId;

                  const headerIndex = headersFinds.findIndex((h) => h.customPayrollTypeId === Number(customPayrollTypeId));
                  let header = null;
                  if (headerIndex !== -1) {
                     header = headersFinds[headerIndex];
                     headersFinds.splice(headerIndex, 1);
                  }
                  return {
                     ...data,
                     _idHeader: header ? header._idHeader : generateUniqueId(),
                     personId: data.person.personId,
                     id: generatedId,
                     employeeId: data.person.employeeId,
                  };
               });

               const newPayrollList = [...existingPayrollList, ...updatedCustomPayrollList];
               dispatch(addCustomPayrollList(newPayrollList));
            }
         } catch (error) {
            setMessage(new Error(error));
         }
      } else {
         dispatch(deleteCustomPayroll({ ids: [personId] }));
      }
   }

   return (
      <Row className="search px-4">
         {/* Selección de Cliente */}
         <Col sm={6} md={3} lg={3}>
            <div className="form-group">
               <p className="text-left label-p">
                  <Translate id="com.tempedge.msg.label.company" />
               </p>
               {clients?.length > 1 && (
                  <DropdownList
                     data={clients}
                     textField="clientName"
                     valueField="clientId"
                     value={selectClient}
                     isInvalid={!!errors.company}
                     onChange={handleSelectClient}
                  />
               )}
            </div>
         </Col>
         <Col sm={6} md={3} lg={3}>
            <div className="form-group">
               <p className="text-left label-p">
                  <Translate id="com.tempedge.msg.label.selectweek" />
               </p>
               <DropdownClientWeekRange
                  client={selectClient}
                  onChange={(value) => {
                     setCustomDate(null);
                     setRangeDate(value);
                  }}
                  value={rangeDate}
               />
            </div>
         </Col>
         {rangeDate?.value === 'CUSTOM' ? (
            <Col sm={6} md={3} lg={3}>
               <div className="form-group">
                  <p className="text-left label-p">
                     <Translate id="com.tempedge.msg.label.date" />
                  </p>
                  <DatePicker
                     onChange={(date) => setCustomDate(moment(date))}
                     selected={customDate ? new Date(customDate) : null}
                     className="form-control tempEdge-input-box"
                     showMonthDropdown
                     showYearDropdown
                     maxDate={null}
                     placeholderText={'MM-DD-YYYY'}
                  />
               </div>
            </Col>
         ) : (
            <Col sm={6} md={3} lg={3}></Col>
         )}
         <Col sm={6} md={3} lg={3}>
            <div className="form-group">
               <p className="text-left label-p">
                  <Translate id="com.tempedge.msg.label.find" />
               </p>
               <BlueCommonBtn
                  tag="com.tempedge.msg.label.find"
                  onClick={() => handleFindPayrollWeek()}
                  disabled={!selectClient}
                  className="blue-common-btn register-save-btn-100"
               />
            </div>
         </Col>
         <Col sm={{ span: 6, offset: 6 }} md={{ span: 3, offset: 9 }} lg={{ span: 3, offset: 9 }}>
            <div className="form-group">
               <p className="text-left label-p">
                  <Translate id="com.tempedge.msg.label.selectemployees" />
               </p>
               <GreenCommonBtn
                  tag="com.tempedge.msg.label.selectemployees"
                  onClick={() => {
                     setModal({
                        show: true,
                        content: (
                           <EmployeeList
                              multipleRows
                              selectRow={(event) => selectRow(event)}
                              onClickRows={() => {
                                 setModal({
                                    show: false,
                                    content: null,
                                    size: null,
                                 });
                              }}
                              isSelected={(personId) => employees.find((employee) => employee.personId === personId)}
                           />
                        ),
                        size: 'modal-lg',
                     });
                  }}
                  disabled={!selectClient}
                  class="blue-common-btn register-save-btn-100"
               />
            </div>
         </Col>
      </Row>
   );
};

export default CustomPayrollSearch;
